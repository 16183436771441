// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";




.handson,
.handson{
  .card-header{
    display: flex;
    align-items: center;
  }
  form{
    &.active{
      display:block;
      right: 0;
      transition-duration: 500ms;
    	transition-property: all;
    	transition-timing-function: ease;
    }
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-left: solid 1px #ccc;
    padding: 20px;
    width: 300px;
    position: fixed;
    top: 55px;
    right: -301px;
    height: 100%;
    z-index: 999;
    transition-duration: 500ms;
  	transition-property: all;
  	transition-timing-function: ease;
  }
  .controllers-search{
    display: flex;
    align-item: center;
    .handson-search{
      display: flex;
      align-items: center;
    }
  }
}


.R_menu{
  form{
    &.active{
      display:block;
      right: 0;
      transition-duration: 500ms;
    	transition-property: all;
    	transition-timing-function: ease;
    }
    .my{
      font-size: 1.2rem;
    }
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-left: solid 1px #ccc;
    padding: 20px;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 0px;
    padding-bottom: 10px;

    width: 300px;
    position: fixed;
    top: 55px;
    right: -301px;
    height: 100%;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;
    transition-duration: 500ms;
  	transition-property: all;
  	transition-timing-function: ease;
  }

}
