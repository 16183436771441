// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import handsontable
@import '~handsontable/dist/handsontable.full.css';

// Import Main styles for this application
@import './scss/style.scss';

body{
  background-color:#2e3e4e;
}
.brand-card-header{
  color:white;
  font-weight:bold;
  background: radial-gradient(#7c8270,#3b5963);
  font-size:1.2em;
}
// .bg-Customer{
// } 
.fa-Customer:before{
  content:'\f2bb';
}
.fa-Document:before{
  content:"\f15c";
}
.fa-CFAR:before{
  content:"\f2db";
}
.fa-Tools:before{
  content:"\f0ad";
}
.fa-Logs:before{
  content:"\f017";
}
.fa-Info:before{
  content:"\f05a";
}
.fa-Users:before{
  content:"\f0c0";
}
.fa-orderDocument:before{
  content:"\f044";
}
.fa-orderCFAR:before{
  content:"\f044";
}
.fa-UpFileList:before{
  content:"\f0ca";
}
.fa-Latest:before{
  content:"\f1da";
}
.fa-Info\/SubconCode:before {
  content: "\f044";
}
.fa-Info\/AgencyInformation:before {
  content: "\f044";
}
.fa-Info\/WECJSales:before {
  content: "\f044";
}
.fa-Info\/ApplicationCode:before {
  content: "\f044";
}
.fa-Info\/WinbondHoliday:before {
  content: "\f044";
}
.fa-Info\/PartNoVsProductNo:before {
  content: "\f044";
}
.fa-UpFileList\/R_ID:before {
  content: "\f044";
}
.fa-UpFileList\/P_ID:before {
  content: "\f044";
}
.fa-UpFileList\/Reflow:before {
  content: "\f044";
}
.fa-UpFileList\/Q_ID:before {
  content: "\f044";
}
.fa-UpFileList\/Mark_ID:before {
  content: "\f044";
}
.fa-UpFileList\/X_ID:before {
  content: "\f044";
}
.fa-UpFileList\/T_ID:before {
  content: "\f044";
}
.fa-UpFileList\/RoHS:before {
  content: "\f044";
}
.fa-UpFileList\/REACH:before {
  content: "\f044";
}
.fa-UpFileList\/MCL:before {
  content: "\f044";
}
.fa-UpFileList\/Z_ID:before {
  content: "\f044";
}
.fa-UpFileList\/M_ID:before {
  content: "\f044";
}
.fa-UpFileList\/W_ID:before {
  content: "\f044";
}
.fa-UpFileList\/S_ID:before {
  content: "\f044";
}
.test{
  display:block;
  margin-left:10px;
  margin-right:0;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.test2 {
  a {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.myStyle_1{
  margin-top:10px;
}
.myStyle_2{
  margin-left:10px;
  margin-right:0;
}
// .fa-facebook-f:before, .fa-facebook:before {
//   content: "\f09a";
// }
// .fa-google-plus:before {
//   content: "\f0d5";
// }

/**/
tbody tr .editCheck{
  background-color:#20a8d8;
}
tbody tr .deleteCheck{
  color:grey;
  pointer-events : none;
  background-color:#f86c6b;
}
.hidden{
  display:none;
  border:solid;
}

.form-control {
  color: #0c86f7;
  font-weight:bold;
}

/* 2021.4 K.Ueoku ↓ ここから 追加 */
/* Request詳細画面　チェックボックス・ラジオボタン　チェック時 */
.checkbox input[type=checkbox].form-check-input:checked + label {
  background-color: #afd7fc;
  color: #0075ff;
  font-weight: bold;
  padding: 0 2px;
}

.radio input[type=radio][value="1"].form-check-input:checked + label {
  background-color: #fecece;
  color: #ea4c89;
  font-weight: bold;
}

.radio input[type=radio][value="0"].form-check-input:checked + label {
  background-color: #e6e6e6;
  font-weight: bold;
}

/* Request詳細画面　ラジオボタンの色変更 */
.radio input[type=radio].form-check-input {
  display: none!important;
}
.radio .form-check-label {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding: 2px 10px 2px 30px;
  margin: 2px 0;
  position: relative;
  width: auto;
}
.radio .form-check-label::before {
  background: #fff;
  border: 1px solid #ea4c89;
  border-radius: 50%;
  content: '';
  display: block;
  height: 14px;
  left: 5px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  width: 14px;
}
.radio .form-check-label::after {
  background: #ea4c89;
  border-radius: 50%;
  content: '';
  display: block;
  height: 8px;
  left: 8px;
  margin-top: -5px;
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 8px;
}

.radio input.form-check-input:checked + label::after {
  opacity: 1;
}

/* Handosontable フィルタドロップダウンメニュー横幅調整　 */
.htDropdownMenu table.htCore {
  min-width: 260px;
}
.htDropdownMenu table.htCore table.htCore {
  width: auto;
  min-width: initial;
  min-width: auto;
}
.htUIMultipleSelect .ht_master .wtHolder {
  overflow-x: scroll;
}

/* Request画面　ファイル添付欄 */
.downloadFilelist {
  background-color: #ffeff2;
  padding-top: 1em;
  padding-bottom: 1em;
}
div.downloadFilelist {
  padding: 1em;
}
ol.downloadFilelist label {
  width: 70%;
  margin-bottom: 0;
}
div.downloadFilelist label {
  width: 75%;
}
div.downloadFilelist small label {
  width: auto;
}
.downloadFilelist label button {
  width: 100%;
}
.downloadFilelist #filesWrap input[type=file] {
  width: 70%;
}
.downloadFilelist #filesWrap {
  display: inline;
}

small {
  color: #20a8d8;
}